<template>
  <b-message :title="title" aria-close-label="Close" type="is-danger">
    <div v-if="detailIsString">
      {{ detail }}
    </div>
    <ul v-else>
      <li v-for="(message, index) in detail" :key="index">
        {{ message }}
      </li>
    </ul>
  </b-message>
</template>

<script>
export default {
  name: 'MessageError',
  props: {
    detail: {
      required: true,
      type: [String, Array]
    },
    title: {
      type: String,
      require: false,
      default: function () {
        return this.$t('messageError.defaultTitle')
      }
    }
  },
  computed: {
    detailIsString() {
      return typeof this.detail === 'string'
    }
  }
}
</script>
